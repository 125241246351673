<template>
  <Main>
    <FormValidationWrap>
      <VerticalFormStyleWrap>
        <sdCards v-if="loading">
          <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;"/>
        </sdCards>
        <template v-else>
          <a-form
            name='sDash_validation-form'
            ref='formRef'
            :model='dataForm'
            :rules="rules"
            layout='vertical'
          >
            <a-row :gutter='25' v-if='!showUsersTable'>
              <div class='participant-form'>
                <a-row>
                  <a-col :xxl='12' :lg='12' :xs='24' class='mb-3'>
                    <span class='title'>
                        {{ `Agregar ${mode === 'assistants' ? 'asistentes' : 'participantes'}` }}
                    </span>
                  </a-col>
                  <a-col :xxl='12' :lg='12' :xs='24' class='mb-3'>
                    <div class='trash-icon'>
                      <button class='btn-show-form' @click='toggleLoadCSV'>
                              <span class='title-normal'>
                                {{ `Agregar ${mode === 'assistants' ? 'asistentes' : 'participantes'} con un csv` }}
                              </span>
                      </button>
                    </div>

                  </a-col>

                  <a-row :gutter='25' class=' mt-3' v-for='(user, indexUser) in usersToRegister' :key='indexUser'>
                    <a-col
                      :xxl='usersToRegister.length>1 ? 22 : 24'
                      :lg='usersToRegister.length>1 ? 22 : 24'
                      :xs='usersToRegister.length>1 ? 22 : 24'
                      class='mb-3'>
                      <div class='individual-form'>
                        <a-row class=' general-form justify-content-between '>
                          <a-col :xxl='5' :lg='7' :xs='24'>
                            <a-form-item ref='curp' name='curp' label='CURP *' class='form-font mx-3 disabled'
                                         :help="user.errors && user.errors?.curp ? user.errors?.curp : ''"
                                         :validate-status="user.errors && user.errors?.curp ? 'error' : ''"
                            >
                              <a-input v-model:value='user.curp' placeholder='' class='input-field' @change="handleChangeCurp($event, indexUser)" />
                            </a-form-item>
                          </a-col>
                          <a-col :xxl='5' :lg='7' :xs='24'>
                            <a-form-item ref='name' name='name' label='Nombre(s)' class='form-font mx-3 disabled'>
                              <a-input v-model:value='user.name' placeholder='' class='input-field' disabled />
                            </a-form-item>
                          </a-col>
                          <a-col :xxl='5' :lg='7' :xs='24'>
                            <a-form-item ref='lastname' name='lastname' label='Apellido Paterno' class='form-font mx-3'>
                              <a-input v-model:value='user.lastname' placeholder='' class='input-field' disabled />
                            </a-form-item>
                          </a-col>
                          <a-col :xxl='5' :lg='7' :xs='24'>
                            <a-form-item ref='lastname2' name='lastname2' label='Apellido Materno' class='form-font mx-3'>
                              <a-input v-model:value='user.second_last_name' placeholder='' class='input-field' disabled />
                            </a-form-item>
                          </a-col>
                          <a-col :xxl='5' :lg='7' :xs='24'>
                            <a-form-item ref='email' name='email' label='Correo electrónico' class='form-font mx-3'>
                              <a-input v-model:value='user.email' placeholder='' class='input-field' disabled />
                            </a-form-item>
                          </a-col>
                          <a-col :xxl='5' :lg='7' :xs='24' v-if='dynamicForm'>
                            <div class='trash-icon'>
                              <button class='btn-show-form' @click='showFormMaker(indexUser)'>
                              <span
                                class='title-normal'> {{ `${user.showFormMaker ? 'Ocultar' : 'Contestar'} formulario`
                                }} </span>
                              </button>
                            </div>


                          </a-col>
                        </a-row>
                        <div v-if='user.showFormMaker' style='border-top: 1px solid #CCCCCC'></div>
                        <div class='event-form' v-if='user.showFormMaker'>
                          <a-col :xxl='24' :lg='24' :xs='24'>
                            <a-row :gutter='25'>
                              <a-col :xxl='24' :lg='24' :xs='24' class='mb-2'>
                                <span class='title'>Información para el evento</span>
                              </a-col>
                              <a-col :xxl='24' :lg='24' :xs='24' class='mb-2'>
                                <span class='title'>{{ `Sección ${user.tabValue + 1}` }}</span>
                              </a-col>
                              <a-col :xxl='24' :lg='24' :xs='24' class='mb-2'>
                                <a-row :gutter='25'>
                                  <a-col :xxl='11' :lg='11' :xs='24'
                                         v-for='(question, idx) in dynamicForm.sections[user.tabValue].questions'
                                         :key='question.id'>

                                  <question-render
                                    :modelValue='user.answers[question.id].content'
                                    :question='question'
                                    :errors='user.errors'
                                    @update:modelValue='handleUpdateValue(user.answers[question.id], $event ,question, user) '
                                    @update:cctmodelValue='searchCCT($event, question, user.tabValue, idx, indexUser)'
                                  />

                                </a-col>
                              </a-row>
                            </a-col>
                          </a-row>
                        </a-col>
                        <div class='form__buttons'>
                          <button class='btn-fill-add'
                                  @click='handleValidateTypeQuestion(dynamicForm.sections[user.tabValue].questions, user, false)'
                                  v-if="user.tabValue > 0">
                            <span class='title-normal'> Ir a la sección anterior </span>
                          </button>
                          <button class='btn-fill-add'
                                  @click='handleValidateTypeQuestion(dynamicForm.sections[user.tabValue].questions, user, true)'
                                  v-if="user.tabValue < dynamicForm?.sections?.length - 1 && !user.endForm">
                            <span class='title-normal'> Ir a la siguiente sección </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </a-col>
                  <a-col :xxl='2' :lg='2' :xs='2' class='mb-3' v-if='usersToRegister.length>1'>
                    <div class='trash-icon'>
                      <sdFeatherIcons class='icon-btn' type='trash' size='21' @click='removeUser(indexUser)' />
                    </div>
                  </a-col>
                </a-row>
                <div class='form__buttons'>
                      <span class='add-user-btn' @click='addUser'>
                        <div class='icon-btn'>
                          <sdFeatherIcons class='icon' type='plus' size='21' />
                        </div>
                          {{ `Agregar ${mode === 'assistants' ? 'asistente' : 'participante'}` }}
                      </span>
                  </div>

                </a-row>

              </div>
            </a-row>
            <a-row :gutter='25' style="margin: 1rem;" v-else>
              <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                <Suspense>
                  <template #default>
                    <users-from-c-c-t-table
                      :data='usersToRegister'
                    />
                  </template>
                </Suspense>
              </a-col>
            </a-row>

            <a-row>
              <div class='form__buttons'>
                <button class='btn-outlined-exit' @click='handleCancel'>
                  <span class='title-normal'> Cancelar </span>
                </button>
                <button class='btn-fill-add'
                        @click='showUsersTable ? handleLoadFromDataTable() : handleOpenModal()'
                        :disabled='!csvValid'
                >

                  <span class='title-normal'> {{ showUsersTable ? 'Continuar' :  'Terminar registro'  }} </span>
                </button>

              </div>
            </a-row>
            <confirm-registration-modal
              :event="event"
              :mode="mode"
              :users="usersToRegister"
              :open-modal="openModal"
              :loading="loading_modal"
              v-on:closeModal="handleCloseModal"
              v-on:submit="handleSubmit"
              :is-sub-event='!!subEventID'
              :parent-name='parentName'
            />
            <curp-users-modal
              :users="curpUsers"
              :openModal="curpModal !== null"
              v-on:closeModal="handleCloseCurpModal"
              v-on:updateUser="updateUserFromModal"
            />

          </a-form>
          <load-c-s-v-modal
            :open-modal='openCSVModal'
            @close-modal='toggleLoadCSV'
            @update:csvData='handleLoadDataFromCSV($event)'
            :event-i-d='subEventID || eventID'
            :max-participants='event.maximum_assistance'
          />
        </template>

      </VerticalFormStyleWrap>
    </FormValidationWrap>


  </Main>
</template>
<script>
import { Main } from '../styled';

import { formatDate } from '@/utility/utility';
import { useStore } from 'vuex';
import { getDetailsEvent, registerToEventWithSubevents, registerUsersToEvent } from '@/services/events';
import { useRoute } from 'vue-router';
import QuestionRender from '@/view/home/overview/QuestionRender';
import ConfirmRegistrationModal from './overview/ConfirmRegistrationModal.vue';
import CurpUsersModal from './overview/CurpUsersModal.vue';
import { Notification } from 'ant-design-vue';
import { ref } from 'vue';
import { getQuestionLevel3CCT } from '@/services/home';
import { getUserByCurp } from '@/services/events'
import * as questionTypes from '@/constants/QuestionTypes';
import * as Yup from 'yup';
import LoadCSVModal from '@/view/home/overview/LoadCSVModal';
import { defineAsyncComponent } from 'vue';

const UsersFromCCTTable = defineAsyncComponent(() => import('./overview/UsersFromCCTTable'));


export default {
  name: 'RegistrationsUsersToEvent',
  components: {
    UsersFromCCTTable,
    LoadCSVModal,
    Main,
    QuestionRender,
    ConfirmRegistrationModal,
    CurpUsersModal
  },
  mounted() {
    this.getEvent();
  },
  data() {
    return {
      usersToRegister: [
        {
          name: '',
          lastname: '',
          second_last_name: '',
          email: '',
          curp: '',
          showFormMaker: false,
          answers: {},
          tabValue: 0,
          errors: {},
          sectionsRoute: [0],
          endForm: false,
        },
      ],
      event: null,
      dynamicForm: null,
      eventID: useRoute().params.id || '',
      mode: useRoute().params.mode || '',
      subEventID: useRoute().query.subEvent || '',
      indexSection: 0,
      openModal: false,
      curpTimer: null,
      curpModal: null,
      curpUsers: [],
      loading: true,
      loading_modal: false,
      parentName: '',
      usersNotFound: "",
      showUsersTable: false,
      openCSVModal: false,
      csvValid: true,
    };
  },
  methods: {
    handleUpdateValue(value, newValue, question, participant){
      value.content = newValue
      if(value.type === 'multipleChoice' || value.type == "dropDown" && question.goto){
        const option = question.options.find(o=>o.id === value.content);
        if(option && option.goto === "finish"){
          participant.endForm = true;
        }else{
          participant.endForm = false;
        }

      }
    },
    handleValidateTypeQuestion(questions, participant, next){
      const sectionIndex  = participant.tabValue;

      const steps = next ? 1 : -1;
      //no validate current section, just go back
      if (!next) {
        participant.sectionsRoute.pop();
        this.handleChangeSection(
          participant.sectionsRoute[participant.sectionsRoute.length - 1],
          steps,
          participant
        )
        return
      }

      //if current section is not valid not continue
      if (!this.validateSection(participant)){
        return;
      }

      //search last question type multipleChoice or dropDown.
      let question = null
      for (let i = questions.length - 1; i >= 0; i--) {
        if (questions[i].type === 'multipleChoice' || questions[i].type === 'dropDown') {
          question = questions[i];
          break;
        }
      }

      //get item ID selected in this question.
      const optionIDSelect = participant.answers[question?.id]?.content

        //if question is defined, this question is  multipleChoice or dropDown
        //if this question.goto = true allow "dynamic sections"
        if (question && question.goto) {
          //find de option selected to compare the "goto" property
          const option = question.options.find(item => item.id === optionIDSelect)
          //Goto next section
          if (option.goto === 'next') {
            this.handleChangeSection( sectionIndex + steps, steps, participant)
          } else if (option.goto === 'finish') {
            //goto last section
            this.handleChangeSection( this.dynamicForm.sections.length - 1, 1, participant)
          } else {
            //go to a specific section with its id
            const indexToGo = this.dynamicForm.sections.indexOf(this.dynamicForm.sections.find(item => item.id === option.goto))
            if(indexToGo != participant.tabValue){
              this.handleChangeSection( indexToGo, steps, participant)
            }
          }

        } else {
          //this question is not multipleChoice or dropdown
          //this question is  multipleChoice or dropDown  but not allow "dynamic sections"
          this.handleChangeSection( sectionIndex + steps, steps, participant)
        }
    },
    async handleChangeSection(nextSection, value, participant){
      if (value == 1) {
        participant.tabValue = nextSection
        participant.sectionsRoute.push(nextSection)
      } else {
        participant.tabValue = nextSection
      }
      console.log(participant.sectionsRoute)
    },
    handleLoadFromDataTable(){
      if(this.dynamicForm ){
        this.usersToRegister.forEach(user=>{
          user['showFormMaker'] = false;
          user['tabValue'] = 0;
          user['errors'] = {};
          user['answers'] = {};
          user['sectionsRoute'] = [0];
          user['endForm'] = false;
            this.dynamicForm.sections.forEach(section => {
              section.questions.forEach(q => {
                user.answers[q.id] = {
                  type: q.type,
                  content: '',
                };
              });
            });
        });
      }
      this.showUsersTable = false;
      this.usersNotFound = "";

    },
    handleLoadDataFromCSV(data){
      this.usersToRegister = data.users;
      this.showUsersTable = true;
      this.usersNotFound = data.usersNotFound;

      if(this.usersNotFound){
        Notification['warning']({message:'Error', description:`CURP(s) no encontrados: ${this.usersNotFound}`})
      }


    },
    toggleLoadCSV(){
      this.openCSVModal = !this.openCSVModal
    },
    handleChangeCurp(event, idxUser) {
      const curp = event.target.value;
      if (this.curpTimer) {
        clearTimeout(this.curpTimer);
      }
      this.curpTimer = setTimeout(async () => {
        this.showUserInfo(this.usersToRegister[idxUser], {curp});
        const response = await getUserByCurp(curp, this.subEventID != '' ?this.subEventID : this.eventID, this.subEventID != '' ? 1 : 0)

        //Filter already registered users
        const ids = this.usersToRegister.map(user => user.id);
        let users = response?.users || [];
        users = users.filter(user => !ids.includes(user.id))

        if (response.success && users.length) {
          if (users.length > 1) {
            //Open modal
            this.curpUsers = users;
            this.curpModal = idxUser;
          } else {
            this.showUserInfo(this.usersToRegister[idxUser], users[0]);
          }
        } else {
          Notification['error']({message:'Error', description:'No hay usuarios disponibles con esa CURP.'})
        }
      }, 1500);
    },
    showUserInfo(user, userInfo) {
      user.id = userInfo.id;
      user.name = userInfo.name;
      user.lastname = userInfo.last_name;
      user.second_last_name = userInfo.second_last_name;
      user.email = userInfo.email;
      user.curp = userInfo.curp;
    },
    updateUserFromModal(userInfo) {
      const user = this.usersToRegister[this.curpModal];
      this.showUserInfo(user, userInfo);
      this.handleCloseCurpModal(false);
    },
    async searchCCT(event, question, sectionIdx, questionIdx, userIndex){
      if (this.timer){
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        //SendRequest
        if (!event) {
          //props.setCCTLoading(false);
          return;
        }
        const response = await getQuestionLevel3CCT(event)
        if (response.success && response?.data?.length) {
          const data =  response?.data ? response.data[0] || {} : {}

          for (let i = 0; i < question.cct.length; i++) {
            const fields = question.cct[i].split('.'); //Split fields by '.'
            let value = data;
            fields.forEach(field => {
              value = value[field];
            })
            let cctFillQuestion = this.dynamicForm.sections[sectionIdx].questions[questionIdx + i + 1];
            this.usersToRegister[userIndex].answers[cctFillQuestion.id].content = value;
          }
          this.usersToRegister[userIndex].errors[question.id] = '';
        } else {
          this.usersToRegister[userIndex].errors[question.id] = 'La clave ingresada no es correcta';
        }
      }, 2000);

      this.usersToRegister[userIndex].answers[question.id].content = event

    },
    showFormMaker(index) {
      this.usersToRegister[index].showFormMaker = !this.usersToRegister[index].showFormMaker;
    },
    removeUser(index) {

      this.usersToRegister.splice(index, 1);
    },
    addUser() {
      const newUser = {
        name: '',
        lastname: '',
        second_last_name: '',
        email: '',
        curp: '',
        showFormMaker: false,
        answers: {},
        tabValue: 0,
        errors: {},
        sectionsRoute: [0],
        endForm: false,
      };
      if(this.dynamicForm ){
        this.dynamicForm.sections.forEach(section => {
          section.questions.forEach(q => {
            newUser.answers[q.id] = {
              type: q.type,
              content: '',
            };
          });
        });
      }

      this.usersToRegister.push(newUser);
    },
    async validateShortAnswer(answer, validations) {
      let error = '';
      //console.log(validations)
      switch(validations.validationType) {
        case 'limits':
          if (validations.characterType == 'number'){ //Validar número
            if (answer.length && !/^\d*$/.test(answer))
              error = "Ingrese un número válido";
          }
          if (validations.min && answer && answer.length < validations.min) {
            error = `El texto debe tener mínimo ${validations.min} caracteres`;
          }
          if (validations.max && answer && answer.length > validations.max) {
            error = `El texto debe tener máximo ${validations.max} caracteres`;
          }
          if (validations.length && answer && answer.length != validations.length) {
            error = `El texto debe tener exactamente ${validations.length} caracteres`;
          }
          break;
        case 'decimal':
          if (answer.length && !/^\d*(\.\d+)?$/.test(answer))
            error = "Ingrese un número válido";
          break;
        case 'predet':
          if (validations.format == 'email') { //Validar email
            try {
              await Yup.string().email().validate(answer)
            } catch {
              error = "Ingrese un email válido";
            }
          } else { //Validar url
            try {
              await Yup.string().url().validate(answer)
            } catch {
              error = "Ingrese una url válida";
            }
          }
          break;
      }
      return error;
    },
    async validateQuestion(question, participant) {
      const answer = participant.answers[question.id].content;
      let error = '';
      if (question.validations.required == '1') {
        let valid = true;
        switch(question.type) {
          case questionTypes.cbx:
            valid = answer && answer.filter(value => value).length;
            break;
          case questionTypes.file:
            valid = answer && answer.length;
            break;
          case questionTypes.matrixTxt:
            for (let key in question.matrixValidations) {
              participant.errors[key] = "";
              if (!answer[key]){
                participant.errors[key] = "Este campo es requerido";
                valid = false;
              }
            }
            break;
          default:
            valid = answer;
        }
        error = valid ? "" : "Este campo es requerido";
      }
      if (question.type == questionTypes.shortAnswer) {
        error = error || await this.validateShortAnswer(answer, question.validations);
      }
      if (question.type == questionTypes.largeAnswer) {
        if (question.validations.min && answer && answer.length < question.validations.min) {
          error = `El texto debe tener mínimo ${question.validations.min} caracteres`;
        }
        if (question.validations.max && answer && answer.length > question.validations.max) {
          error = `El texto debe tener máximo ${question.validations.max} caracteres`;
        }
        if (question.validations.length && answer && answer.length != question.validations.length) {
          error = `El texto debe tener exactamente ${question.validations.length} caracteres`;
        }
      }
      if (question.type == questionTypes.cct) {
        error = participant.errors[question.id] || error;
      }
      if (question.type == questionTypes.matrixTxt) {
        for (let key in question.matrixValidations) {
          participant.errors[key] = participant.errors[key] || await this.validateShortAnswer(answer[key], question.matrixValidations[key]);
        }
      }
      participant.errors[question.id] = error;
    },
    async validateSection(participant) {
      //console.log(this.dynamicForm.sections[participant.tabValue].questions);
      for (let i in this.dynamicForm.sections[participant.tabValue].questions) {
        const question = this.dynamicForm.sections[participant.tabValue].questions[i];
        await this.validateQuestion(question, participant);
      }
      //console.log(this.usersToRegister[0].errors)
      //for(let partipant in this.usersToRegister){}
      for (let error in participant.errors) {
        if (participant.errors[error])
          return false;
      }
      return true;
    },

    async handleOpenModal() {

      let error = false;
      for (let participant of this.usersToRegister) {
        participant.errors["curp"] = "";
        if (!participant.curp) {
          participant.errors["curp"] = "Este campo es requerido";
          error = true;
          continue;
        }
        if(!participant.id) {
          participant.errors["curp"] = "Ingresa una CURP válida";
          Notification['error']({message:'Error', description:'Ingresa una CURP válida'})
          error = true;
        }
      }
      if (error) {
        return;
      }
      for (let participant of this.usersToRegister) {
        if(this.dynamicForm ){
          if (!await this.validateSection(participant)) {
            Notification['error']({message:'Llena todos los campos', description:'Error'});
            return;
          }
        }
      }
      this.openModal = true;
    },
    handleCloseModal() {
      this.openModal = false;
    },
    handleCloseCurpModal(clear = true) {
      if (clear) {
        this.usersToRegister[this.curpModal].curp = '';
      }
      this.curpModal = null;
    },
    async handleSubmit() {
      const toEvent = !this.subEventID;

      this.usersToRegister.forEach(participant=>{
        if(this.dynamicForm ){
          if (!this.validateSection(participant))
            Notification['error']({message:'Llena todos los campos', description:'Error'})
          return;
        }
      })

      const data = {
        event_id: this.eventID,
        assistant: this.mode === 'assistants' ? true : false,
        users: this.usersToRegister,
        subevents: [this.event],
        personal_register: false,
        eventToCalendar: true
      }
      const form = new FormData();
      this.formatData(form, data);
      this.loading_modal = true;

      //console.log(data);
      if(toEvent){
        await registerUsersToEvent(form).then((response) => {
          if (response.success) {
            this.showNotificationAndRedirect('success', 'Éxito', response.message, this.event, this.eventID);
          } else {
            this.showNotificationAndRedirect(response.cancelled ? 'warning' : 'error', 'Error', response.message);
          }
        }).catch((error) => {
          console.log(error);
        });
      }else{
        await registerToEventWithSubevents(this.eventID, form).then((response) => {
          if (response.success) {
            this.showNotificationAndRedirect('success', 'Éxito', response.message, this.event, this.subEventID);
          } else {
            this.showNotificationAndRedirect(response.cancelled ? 'warning' : 'error', 'Error', response.message);
          }
        }).catch((error) => {
          console.log(error);
        });
      }


      this.loading_modal = false;
    },
    formatData: function (form, data, prefix = '') {
      for (let key in data) {
        if ((typeof data[key] === 'object') && !(data[key] instanceof  File)) {
          this.formatData(form, data[key], `${prefix}${key}${prefix ? ']' : ''}[`);
        } else {
          if(typeof data[key] === 'boolean') data[key] = data[key] ? 1 : 0;
          form.append(`${prefix}${key}${prefix ? ']' : ''}`, data[key]);
        }
      }
    },
    showNotificationAndRedirect(type, message, description, eventResponse = null, eventID = null){
      Notification[type]({
        message,
        description,
      });
      if (type == 'success') {
        setTimeout(() => {
          this.$router.push(
            {
              name: `responseRegistrations`,
              params: {
                module: this.mode === 'assistants' ? 'asistentes' : 'participantes',
                event: JSON.stringify(eventResponse),
                eventID,
                hasSubEvent: Boolean(this.subEventID)
              }
            })
        }, 1000);
      }
    },
    handleCancel() {
      this.$router.go(-1);
    },
    async getEvent() {
      await getDetailsEvent(this.eventID, true).then((response) => {
        const isSubEvent = Boolean(this.subEventID)
        if (response.success) {
          //this.eventID = response.event.id
          this.parentName = response.event?.title;
          this.event = isSubEvent
            ? response.event?.subevents?.find(sub => sub.id == this.subEventID)
            : response.event;

          if (this.event.activities && this.mode == 'participants') {
            this.dynamicForm = isSubEvent
              ? JSON.parse(this.event.dynamicform.questions_json)
              : JSON.parse(this.event.dynamic_form.questions_json);

            this.dynamicForm.sections.forEach(section => {
              section.questions.forEach(q => {
                this.usersToRegister[0].answers[q.id] = {
                  type: q.type,
                  content: '',
                };
              });
            });
          }
        } else {
          Notification['error']({message: response.message, description:'Error'})
        }
      }).catch((error) => {
        console.log(error);
      });

      this.loading = false;

    },
  },
  async setup() {
    const { dispatch } = useStore();
    const formRef = ref();
    //const router = useRoute();
    const mode = useRoute().params.mode || '';

    dispatch('changeTitleHeader', `Registrar  ${mode === 'assistants' ? 'asistentes' : 'participantes'}`);

    return {
      formatDate,
      formRef,
    };
  },

};

</script>

<style scoped lang='sass'>
.participant-form
  border-radius: 21px
  background-color: #FFFFFF

  margin: 32px
  width: 100%
  height: auto
  padding: 32px 21px

  .individual-form

    border: 1px solid #D3D3D3
    border-radius: 18px

    .general-form
      margin: 32px 0
      padding: 21px 30px
      width: 100%

    .event-form
      padding: 0 21px
      padding: 32px 21px



  .title
    font-family: 'Nutmeg Bold'
    color: #404040
    font-size: 18px

.trash-icon
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  width: 100%
  color: #E8394D

  &:hover
    cursor: pointer


.form__buttons
  padding: 32px 21px
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  gap: 15px

  .add-user-btn
    font-size: 16px
    font-family: 'Avenir Black'

    .icon-btn
      display: inline-block
      width: 24px
      height: 24px
      border-radius: 50%
      padding-left: 1px


      background-color:#E8394D
      color: #FFFFFF
    color: #E8394D

    &:hover
      cursor: pointer


.disabled
  color: #7B868C !important

button
  padding: 5px 15px 5px 15px
  border-radius: 24px


.btn-show-form
  background: #E8394D
  color: #ffffff
  border: 1px solid #E8394D

.btn-fill-add
  border: 1px solid #9ED2EF
  color: #ffffff
  background: #3FA7E1
  &:disabled
    background: rgba(63, 167, 225, 0.2)


button:hover
  cursor: pointer

.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  background: none
  margin-left: 2rem

</style>