<template>
  <sdModal
    type="primary"
    :width="700"
    :visible="openModal"
    :onCancel="handleCloseModal"
    class="title-bold-normal"
  >
    <a-row :gutter="25"  style="text-align: left;">
      <a-col :xxl="24" :lg="24" :md="24" :xs="24" >
        <h3 class="title-normal modal-title">
          Registro a Eventos
        </h3>
      </a-col>
    </a-row>
    <a-row :gutter="25" align="middle" style="text-align: left;" v-for="(user, i) in users" :key="i">
      <a-col :xs="2">
        <a-checkbox class="form-font" :checked="false" @click="selectUser(user)"></a-checkbox>
      </a-col>
      <a-col :xxl="22" :lg="22" :md="22" :xs="22" >
        <div class="card mt-3 mb-3 pl-4 pr-4">
          <a-row :gutter="25">
            <a-col :xs="8">
              <span class="card-title">Curp:</span>
            </a-col>
            <a-col :xs="16">
            <span class="card-text">{{user.curp}}</span>
            </a-col>
            <a-col :xs="8">
              <span class="card-title">Nombre completo:</span>
            </a-col>
            <a-col :xs="16">
              <span class="card-text">{{user.name}} {{user.last_name}} {{user.second_last_name}}</span>
            </a-col>
            <a-col :xs="8">
              <span class="card-title">Correo electrónico:</span>
            </a-col>
            <a-col :xs="16">
              <span class="card-text">{{user.email}}</span>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </sdModal>
</template>
<script>


export default {
  name: 'CurpUsersModal',
  components: {
  },
  props: [
    'openModal',
    'mode',
    'event',
    'users'
  ],
  emits: ['closeModal', 'updateUser'],
  methods: {
    handleCloseModal() {
      this.$emit('closeModal');
    },
    selectUser(user) {
      console.log(user)
      this.$emit('updateUser', user);
    },

  }
};

</script>

<style scoped lang='sass'>
.modal-title
  font-weight: 600
  font-size: 1.4rem

.card
  border: 1px solid #D3D3D3
  border-radius: 25px
  font-family: Nutmeg
  padding: 1rem

  .card-text
    font-weight: 500

  .card-title
    font-family: 'Nutmeg Bold'

.bg-gray
  background: #BCBDBE40
  border: none

.form__buttons
  padding: 32px 21px
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  gap: 15px

  .add-user-btn
    font-size: 16px
    font-family: 'Avenir Black'

    .icon-btn
      display: inline-block
      width: 24px
      height: 24px
      border-radius: 50%
      padding-left: 1px

      background-color:#E8394D
      color: #FFFFFF
    color: #E8394D

    &:hover
      cursor: pointer

button
  padding: 5px 15px 5px 15px
  border-radius: 24px

.btn-fill-add
  border: 1px solid #9ED2EF
  color: #ffffff
  background: #3FA7E1

.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  background: none
</style>