<template>
  <sdModal
    type="primary"
    :width="700"
    :visible="openModal"
    :onCancel="handleCloseModal"
    class="title-bold-normal"
  >
    <a-row :gutter="25"  style="text-align: left;" class='mt-3' v-if='!loading'>
      <a-col :xxl="10" :lg="10" :md="24" :xs="24" >
        <a-upload
          name="files"
          accept=".csv"
          :multiple="false"
          :fileList="files"
          :remove="deleteFile"
          :beforeUpload="chooseFiles"
        >
          <button class="btn-add-outlined">
            <img
              :src="require(`@/static/img_events/SEJ enventos_adjuntos.svg`)"
              alt="logo"
              width="16"
              height="16"
              style="margin-right: 0.6rem"
            />
            <span class="title-normal"> Adjuntar archivos </span>
          </button>
        </a-upload>

      </a-col>
      <a-col :xxl="14" :lg="14" :md="24" :xs="24" >
        <span class="form-font helper-text">
          Los archivos seleccionados no deben pesar más de 20 MB en conjunto. Archivos permitidos: CSV
          </span>
      </a-col>
    </a-row>
    <a-row :gutter="25"  style="text-align: left;" class='mt-5' v-else>
      <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;"/>
    </a-row>

  </sdModal>
</template>
<script>


import { Notification } from 'ant-design-vue';
import Papa from 'papaparse'
import { getUserByCurp } from '@/services/events';

export default {
  name: 'LoadCSVModal',
  data() {
    return {
      totalWeight: 0,
      files: [],
      loading:false,

    }
  },
  components: {
  },
  props: [
    'openModal',
    'eventID',
    'isSubEvent',
    'maxParticipants'
  ],
  emits: ['close-modal', 'update:csvData'],
  methods: {
    handleCloseModal() {
      this.$emit('close-modal');
    },
    handleError(message) {
      Notification['error']({
        message: 'Error',
        description:
        message,
      });
    },
    chooseFiles(file) {
      this.loading = true;
      const maxWeight = 20971520; //20 megabytes
      if (file.size <= maxWeight ) {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: function( results ){
            if(results.data.length === 0){
              const message = this.files.length >= 1
                ? "Sin datos"
                : `El documento ${file.name} no contiene información`
              this.handleError(message)
            }
            else if(this.maxParticipants && results.data.length > this.maxParticipants){
              const message = this.files.length >= 1
                ? "Límite de participantes alcanzado"
                : `El documento ${file.name} excede el número de usuarios permitidos en el evento`
              this.handleError(message);
            }else{
              this.getUsersByCURP(results.data)
            }

          }.bind(this)
        } );

        //files.push(file);

      } else {
        const message = this.files.length >= 1
          ? "Límite de archivos alcanzado"
          : `El documento ${file.name} excede el peso permitido en la carga de archivos (20Mb).`
        this.handleError(message);
      }
      this.loading = false;
      return false;

    },
    deleteFile(file) {
      const index = this.files.indexOf(file);
      const files = this.files;
      this.totalWeight = this.totalWeight - file.size;
      files.splice(index, 1);
      this.$emit('update:modelValue', files);
    },
    async getUsersByCURP(usersToValidate){

      this.loading = true
      let data = {
        users:[],
        usersNotFound: ""
      };
      for (let user of usersToValidate){
        const response =  await getUserByCurp(user.curp, this.eventID, 1)
        let users = response?.users || [];
        if (response?.success) {
          if(users.length >=1){
            data.users.push({ ...response.users[0], errors:{} });
          }else{
            data.usersNotFound += user.curp+", ";
          }
        }
      }
      data.usersNotFound.slice(0, -2);
      this.$emit('update:csvData', data);
      this.loading = false;
      this.handleCloseModal();

    },


  }
};

</script>

<style scoped lang='sass'>
.btn-add-outlined
  background-color: white
  border: 2px solid #e70e4c
  border-radius: 20px
  color: #e70e4c
  padding: .5rem 1.5rem

.modal-title
  font-weight: 600
  font-size: 1.4rem

.card
  border: 1px solid #D3D3D3
  border-radius: 25px
  font-family: Nutmeg
  padding: 1rem

  .card-text
    font-weight: 500

  .card-title
    font-family: 'Nutmeg Bold'

.bg-gray
  background: #BCBDBE40
  border: none

.form__buttons
  padding: 32px 21px
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  gap: 15px

  .add-user-btn
    font-size: 16px
    font-family: 'Avenir Black'

    .icon-btn
      display: inline-block
      width: 24px
      height: 24px
      border-radius: 50%
      padding-left: 1px

      background-color:#E8394D
      color: #FFFFFF
    color: #E8394D

    &:hover
      cursor: pointer

button
  padding: 5px 15px 5px 15px
  border-radius: 24px

.btn-fill-add
  border: 1px solid #9ED2EF
  color: #ffffff
  background: #3FA7E1

.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  background: none
</style>