<template>
  <sdModal
    type="primary"
    :width="700"
    :visible="openModal"
    :onCancel="handleCloseModal"
    class="title-bold-normal"
  >
    <a-row :gutter="25"  style="text-align: left;">
      <a-col :xxl="24" :lg="24" :md="24" :xs="24" >
        <h3 class="title-normal modal-title">
          Registro a Eventos
        </h3>
      </a-col>
    </a-row>
    <a-row :gutter="25"  style="text-align: left;">
      <a-col :xxl="24" :lg="24" :md="24" :xs="24" >
        <div class="card bg-gray mt-3 mb-3 pl-4 pr-4">
          <a-row :gutter="25" v-if='isSubEvent'>
            <a-col class='parentName' :xxl="24" :lg="24" :md="24" :xs="24" style='border-bottom: 1px solid #707070' >
              {{parentName}}
            </a-col>
          </a-row>
          <a-row :gutter="25">
            <a-col :xs="8"><span class="card-title">Nombre del evento:</span></a-col>
            <a-col :xs="16"><span class="card-text"> {{event.title}} </span></a-col>
            <a-col :xs="8"><span class="card-title"> Fecha del registro: </span></a-col>
            <a-col :xs="16"><span class="card-text"> {{showDate(null)}} </span></a-col>
            <a-col :xs="8"><span class="card-title"> Sede: </span></a-col>
            <a-col :xs="16">
              <a-row :gutter="25">
                <a-col :xs="24" v-for="headquarter in event.headquarters" :key="headquarter">
                  <span class="card-text">{{ formatHeadquarter(headquarter) }}</span>
                </a-col>
              </a-row>
            </a-col>
            <a-col :xs="8"><span class="card-title"> Fecha del evento: </span></a-col>
            <a-col v-if='!isSubEvent' :xs="16"><span class="card-text"> {{showDate(event.event_date)}},{{event.event_hour}}hrs </span></a-col>
            <a-col v-else :xs="16"><span class="card-text"> {{showDate(event.event_start_date)}},{{event.event_start_hour}}hrs </span></a-col>
          </a-row>
        </div>
        <div class="card mt-3 mb-3 pl-4 pr-4" v-for="(user, i) in users" :key="i">
          <a-row :gutter="25" >
            <a-col :xs="8">
              <span class="card-title">Curp:</span>
            </a-col>
            <a-col :xs="16">
            <span class="card-text">{{user.curp}}</span>
            </a-col>
            <a-col :xs="8">
              <span class="card-title">Nombre completo:</span>
            </a-col>
            <a-col :xs="16">
              <span class="card-text">{{user.name}}</span>
            </a-col>
            <a-col :xs="8">
            <span class="card-title">Tipo:</span>
            </a-col>
            <a-col :xs="16">
              <span class="card-text">{{mode === 'participants' ? 'Participante' : 'Asistente'}}</span>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
    <sdCards v-if="loading">
      <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;"/>
    </sdCards>
    <a-row v-else :gutter="25" justify="center" style="text-align: center; margin-top: 2rem;">
      <a-col :xxl="8" :lg="8" :md="12" :xs="24" >
        <button class="btn-outlined-exit mt-2">
          <span class="title-normal" v-on:click="handleCloseModal"> Seguir agregando </span>
        </button>
      </a-col>
      <a-col :xxl="8" :lg="8" :md="12" :xs="24" >
        <button class="btn-fill-add mt-2">
          <span class="title-normal" v-on:click="handleSubmit"> Guardar registro </span>
        </button>
      </a-col>
    </a-row>
  </sdModal>
</template>
<script>

import {formatDate} from '@/utility/utility';

export default {
  name: 'ConfirmRegistrationModal',
  components: {
  },
  props: [
    'openModal',
    'mode',
    'event',
    'users',
    'loading',
    'isSubEvent',
    'parentName'
  ],
  emits: ['closeModal', 'submit'],
  methods: {
    handleCloseModal() {
      this.$emit('closeModal');
    },
    handleSubmit() {
      this.$emit('submit');
    },
    formatHeadquarter(headquarter) {
        switch (headquarter.type) {
            case "face-to-face": {
              return `${headquarter.name}, ${headquarter.street}, ${headquarter.suburb != null ? headquarter.suburb : headquarter.other_suburb}, ${headquarter.municipality}, ${headquarter.postal_code}`
            }
            case "virtual": {
              return `${headquarter.virtual_platform}, ${headquarter.link}`
            } 
            default :{
              return `${headquarter.virtual_platform}, ${headquarter.link}, ${headquarter.name}, ${headquarter.street}, ${headquarter.suburb != null ? headquarter.suburb : headquarter.other_suburb}, ${headquarter.municipality}, ${headquarter.postal_code}`
            }
        }
    },
    showDate(date) {
      if(date){
        return formatDate(date);
      }else{
        const dateToFormat = new Date();
        const today = dateToFormat.getFullYear() + '-' + (dateToFormat.getMonth() + 1 < 10 ? '0' : '') + (dateToFormat.getMonth() + 1) + '-' + dateToFormat.getDate();
        return formatDate(today)
      }



    },
  }
};

</script>

<style scoped lang='sass'>
.modal-title
  font-weight: 600
  font-size: 1.4rem

.card
  border: 1px solid #D3D3D3
  border-radius: 25px
  font-family: Nutmeg
  padding: 1rem

  .card-text
    font-weight: 500
    font-family: 'Avenir Roman'

  .card-title
    font-family: 'Nutmeg Bold'

  .parentName
    font-family: 'Nutmeg Bold'
    font-size: 18px
    padding-bottom: 12px
    margin-bottom: 15px


.bg-gray
  background: #BCBDBE40
  border: none

.form__buttons
  padding: 32px 21px
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  gap: 15px

  .add-user-btn
    font-size: 16px
    font-family: 'Avenir Black'

    .icon-btn
      display: inline-block
      width: 24px
      height: 24px
      border-radius: 50%
      padding-left: 1px

      background-color:#E8394D
      color: #FFFFFF
    color: #E8394D

    &:hover
      cursor: pointer

button
  padding: 5px 15px 5px 15px
  border-radius: 24px
  &:hover
    cursor: pointer

.btn-fill-add
  border: 1px solid #9ED2EF
  color: #ffffff
  background: #3FA7E1

.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  background: none
</style>